import {ChangeEvent, FC, FormEvent} from 'react';

interface Props<InputT> {
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  label?: string;
  onChange: (e: ChangeEvent<any>) => void;
  placeholder?: string;
  rows?: number;
  type?: string;
  value: InputT;
  name: string;
  maxLength?: number;
  handleKeyDown?: boolean;
  onSubmit?: (e?: FormEvent) => void;
}

const TextArea: FC<Props<any>> = (
  props = {
    disabled: false,
    rows: 3,
    type: 'text',
    onChange: () => { return; },
    handleKeyDown: false,
    value: '',
    name: '',
  },
) => {
  const handleKeyDown = (event) => {
    if (props.onSubmit && (event.ctrlKey || event.metaKey) && event.key === "Enter") {
      event.preventDefault(); // Evita il ritorno a capo
      props.onSubmit(); // Effettua il submit del form
    }
  }
  return (
    <div className={props.className}>
      <label className="block m-0">
        {props.label && (
          <span className="text-sm block text-gray-500 mb-1">
            {props.label}
          </span>
        )}
        <textarea
          className={[
            'form-textarea block w-full rounded-md shadow-xs focus:border-gray-300 focus:ring-3 focus:ring-gray-200/50 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-500 disabled:border-gray-200 disabled:shadow-none',
            props.errorMessage
              ? 'border-red-500 bg-red-50'
              : 'border-gray-300 bg-white',
          ].join(' ')}
          value={props.value}
          name={props.name}
          onChange={(event) => props.onChange && props.onChange(event)}
          disabled={props.disabled}
          placeholder={props.placeholder}
          rows={props.rows}
          maxLength={props.maxLength}
          onKeyDown={(event) => props.handleKeyDown && props.onSubmit && handleKeyDown(event)}
        />
      </label>
      {props.errorMessage && (
        <span className="text-red-500 text-xs">{props.errorMessage}</span>
      )}
    </div>
  );
};
export default TextArea;
